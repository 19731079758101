<template>
  <b-row class="deactivated-page">
    <b-col class="mt-5">
      <h1>
        {{ $t("deactivated.trulySorry") }}
      </h1>
      <h2>{{ $t("deactivated.accountDeactivated") }}</h2>
      <p>
        {{ $t("deactivated.youCan") }}
        <b-link to="/register"> {{ $t("deactivated.regNew") }}</b-link>
        {{ $t("deactivated.or") }}
        <b-link to="/"> {{ $t("deactivated.signIn") }}</b-link>
        {{ $t("deactivated.diffAcc") }}.
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "deactivated",
};
</script>

<style lang="scss" scoped>
.deactivated-page {
  height: 100%;
  align-items: center;
  text-align: center;
}
a {
  text-decoration: underline;
}
</style>